<template>
  <div class="px-2">
    <h4 class="my-2">Painel de vendas</h4>
    <b-card no-body>
      <b-table
        responsive
        striped
        :items="products"
        :fields="fieldsProducts"
        class="mb-0"
      >
        <template #cell(title)="{ item }">
          <button
            style="height: 42px"
            class="btn btn-block btn-dark btn-block text-left"
            type="button"
            @click="openModalProduct(item)"
          >
            <strong>
              {{ item.title }}
            </strong>
          </button>
        </template>

        <template #cell(installments)="{ item }">
          <button
            style="height: 42px"
            type="button"
            class="btn btn-sm btn-block btn-dark"
            @click="openModalInstallments(item)"
          >
            Parcelamento
            <b-badge variant="light" style="margin-left: 5px; color: black">
              {{ item.installments.length }}
            </b-badge>
          </button>
        </template>

        <template #cell(redirect)="{ item }">
          <button
            style="height: 42px"
            @click="openModalSearchCustomer(item, 'product')"
            type="button"
            class="btn btn-block btn-sm btn-dark"
          >
            Matricular
          </button>
        </template>
      </b-table>

      <b-table
        responsive
        striped
        :items="courses"
        :fields="fieldsCourses"
        class="mb-0"
      >
        <template #cell(course_title)="{ item }">
          <button
            class="btn btn-block btn-green text-left"
            type="button"
            @click="openModalCourse(item)"
          >
            <strong>
              {{ item.course_title }}
            </strong>
          </button>
        </template>

        <template #cell(classrooms)="{ item }">
          <button
            type="button"
            class="btn btn-sm btn-block btn-green"
            @click="openModalClassroom(item)"
          >
            Turmas
            <b-badge variant="light" style="margin-left: 5px; color: black">
              {{ item.classrooms.length }}
            </b-badge>
          </button>
        </template>

        <template #cell(disciplines)="{ item }">
          <button
            type="button"
            class="btn btn-sm btn-block btn-green"
            @click="openModalDisciplines(item)"
          >
            Disciplinas
            <b-badge variant="light" style="margin-left: 5px; color: black">
              {{ item.disciplines.length }}
            </b-badge>
          </button>
        </template>

        <template #cell(installments)="{ item }">
          <button
            type="button"
            class="btn btn-sm btn-block btn-green"
            @click="openModalInstallments(item)"
          >
            Parcelamento
            <b-badge variant="light" style="margin-left: 5px; color: black">
              {{ item.installments.length }}
            </b-badge>
          </button>
        </template>
      </b-table>
    </b-card>

    <b-modal
      hide-footer
      scrollable
      size="lg"
      title="Detalhes do produto"
      id="modal-product"
      modal-class="modal-primary"
    >
      <h6 class="mb-2" v-if="product">{{ product.title }}</h6>
      <div
        class="alert alert-default p-1"
        style="background: #f5f5f5bf; color: #000; border: 1px solid #c9c9c9"
        v-if="product && product.datasheet"
        v-html="product.datasheet"
      ></div>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      hide-footer
      title="Detalhes do Curso"
      centered
      size="lg"
      id="modal-course"
      modal-class="modal-primary"
    >
      <h6 class="mb-2">{{ course.course_title }}</h6>
      <div
        class="alert alert-default p-1"
        style="background: #f5f5f5bf; color: #000; border: 1px solid #c9c9c9"
        v-if="course.datasheet && course.datasheet.description"
        v-html="course.datasheet.description"
      ></div>
      <b-table
        responsive
        :items="[course.datasheet]"
        striped
        :fields="fieldsDatasheet"
        class="mb-2"
      >
        <template #cell(situation)="">
          {{ course.datasheet.situation_name }}
        </template>

        <template #cell(remuneration)="">
          {{ course.datasheet.remuneration_prefix }}
          {{ course.datasheet.remuneration_value }}
        </template>

        <template #cell(levels)="{ item }">
          <span v-for="(level, index) in item.levels" :key="index">
            {{ level.name }}
            <span v-if="index !== item.levels.length - 1">,</span>
          </span>
        </template>

        <template #cell(contract_model)="{ item }">
          {{ item.contract_model }}
        </template>

        <template #cell(states)="{ item }">
          <span v-for="(state, index) in item.states" :key="index">
            {{ state.title }}
            <span v-if="index !== item.states.length - 1">,</span>
          </span>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      hide-footer
      title="Calendário"
      centered
      size="xl"
      id="modal-classrooms"
      modal-class="modal-primary"
    >
      <h6 class="mb-2">{{ course.course_title }}</h6>
      <b-table
        responsive
        striped
        :items="itemsClassrooms"
        :fields="fieldsClassrooms"
        primary-key="classroom_name"
        class="mb-0"
      >
        <template #cell(days)="{ item }">
          <span v-for="(day, index) in item.days" :key="index">
            {{ day.name }}
            {{ index !== item.days.length - 1 ? "," : "" }}
          </span>
        </template>

        <template #cell(confirmed)="{ item }">
          <i
            v-if="item.confirmed"
            class="bi bi-patch-check-fill text-success"
          ></i>
        </template>

        <template #cell(unit_name)="{ item }">
          {{ item.unit_name || "Live" }}
        </template>

        <template #cell(classroom_name)="{ item }">
          {{ item.classroom_name }}
        </template>

        <template #cell(actions)="{ item }">
          <button
            @click="openModalSearchCustomer(item, 'classroom')"
            type="button"
            class="btn btn-sm btn-primary"
          >
            Matricular
          </button>
        </template>

        <template #cell(period)="{ item }">
          {{ item.shift_name }} - {{ item.start_time }} às {{ item.end_time }}
        </template>
      </b-table>
    </b-modal>

    <b-modal
      no-close-on-backdrop
      hide-footer
      title="Disciplinas e Carga Horária"
      centered
      size="md"
      id="modal-disciplines"
      modal-class="modal-primary"
    >
      <h6 class="mb-2">{{ course.course_title }}</h6>
      <b-table
        responsive
        striped
        :items="itemsDisciplines"
        :fields="fieldsDisciplines"
        class="mb-0"
      ></b-table>
      <hr />
      <div class="form-row mt-2">
        <div class="col-md-9 text-right text-success">
          <strong>Total</strong>
        </div>
        <div class="col-md-3 text-center">
          <strong>{{ this.totalWorkload }} horas</strong>
        </div>
      </div>
    </b-modal>

    <b-modal
      no-body
      no-close-on-backdrop
      hide-footer
      title="Parcelamento"
      centered
      size="lg"
      id="modal-installments"
      modal-class="modal-primary modal-installments"
    >
      <h6 class="mb-2">{{ course.course_title }}</h6>
      <b-table
        responsive
        striped
        :items="itemsInstallments"
        :fields="fieldsInstallments"
        class="mb-0"
      >
        <template #cell(value)="{ item }">
          {{ item.value | toCurrency }}
        </template>

        <template #cell(card_debit)="{ item }">
          <i
            v-if="item.card_debit"
            class="bi bi-patch-check-fill text-success"
          ></i>
        </template>
        <template #cell(card_credit)="{ item }">
          <i
            v-if="item.card_credit"
            class="bi bi-patch-check-fill text-success"
          ></i>
        </template>
        <template #cell(pix)="{ item }">
          <i v-if="item.pix" class="bi bi-patch-check-fill text-success"></i>
        </template>
        <template #cell(money)="{ item }">
          <i v-if="item.money" class="bi bi-patch-check-fill text-success"></i>
        </template>
        <template #cell(check)="{ item }">
          <i v-if="item.check" class="bi bi-patch-check-fill text-success"></i>
        </template>
        <template #cell(billet)="{ item }">
          <i v-if="item.billet" class="bi bi-patch-check-fill text-success"></i>
        </template>
        <template #cell(letter)="{ item }">
          <i v-if="item.letter" class="bi bi-patch-check-fill text-success"></i>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      no-body
      no-close-on-backdrop
      hide-footer
      title="Pesquisar Cliente"
      centered
      id="modal-search-customer"
      modal-class="modal-search-customer"
    >
      <div v-if="classroom" class="form-row mb-0">
        <div class="col-md-9">
          <div class="form-group">
            <label for="">Curso</label>
            <input
              type="text"
              disabled
              :value="course.course_title"
              class="form-control"
            />
          </div>
        </div>
        <div v-if="classroom" class="col-md-3">
          <div class="form-group">
            <label for="">Turma</label>
            <input
              type="text"
              disabled
              :value="classroom.classroom_name"
              class="form-control"
            />
          </div>
        </div>
        <div v-if="classroom" class="col-md-4">
          <div class="form-group mb-md-0">
            <label for="">Unidade</label>
            <input
              type="text"
              disabled
              :value="classroom.unit_name"
              class="form-control"
            />
          </div>
        </div>
        <div v-if="classroom" class="col-md-4">
          <div class="form-group mb-md-0 mb-0">
            <label for="">Horário</label>
            <input
              type="text"
              disabled
              :value="`${classroom.start_time} às ${classroom.end_time}`"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <h4 v-else-if="product">{{ product.title }}</h4>

      <hr />
      <div class="form-row">
        <div class="col-md-3">
          <label for="">CPF</label>
          <input
            type="tel"
            v-mask="['###.###.###-##']"
            v-model="searchCustmer.cpf"
            class="form-control"
          />
        </div>

        <div class="col-md-5">
          <label for="">E-mail</label>
          <input
            type="text"
            v-model="searchCustmer.email"
            class="form-control"
          />
        </div>

        <div class="col-md-3">
          <label for="">Celular</label>
          <input
            v-mask="['(##) #####-####', '(##) ####-####']"
            type="tel"
            v-model="searchCustmer.cellphone"
            class="form-control"
          />
        </div>

        <div class="col-md-1">
          <button
            :disabled="btnSearchCustomer"
            style="
              margin-top: 20px;
              padding: 5px 5px !important;
              line-height: 2.5;
            "
            type="button"
            class="btn btn-block btn-warning"
            @click="submitSearchCustomer"
          >
            <i v-if="!btnSearchCustomer" class="bi bi-search"></i>
            <b-spinner v-else small class="align-middle"></b-spinner>
          </button>
        </div>
      </div>

      <p
        v-if="btnSearchCustomer"
        class="mt-2 d-flex justify-content-left items-center text-left"
      >
        <b-spinner
          small
          class="align-middle"
          style="margin-right: 8px"
        ></b-spinner>
        buscando cliente
      </p>

      <div v-if="customers && customers.length" class="w-full d-block mt-3">
        <h5>
          Clientes encontrados <small>({{ customers.length }})</small>
        </h5>
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(customer, index) in customers"
            :key="`c-${index}`"
          >
            <div
              class="d-flex justify-content-between items-center"
              style="align-items: center"
            >
              <div>
                {{ customer.full_name }} - {{ toMaskCpf(customer.cpf) }}
              </div>
              <div>
                <button
                  @click="redirectToPage(customer)"
                  class="btn btn-block btn-link btn-sm"
                  type="button"
                >
                  Matricular
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else-if="!btnSearchCustomer && customers" class="mt-2">
        <div class="d-flex justify-content-between items-center">
          <div>Não encontramos nenhum cliente.</div>
          <button
            type="button"
            @click="openModalCustomerCreate"
            class="btn btn-sm btn-primary"
          >
            Cadastrar Cliente
          </button>
        </div>
      </div>
    </b-modal>
    <CustomerModalCreate />
  </div>
</template>

<style lang="scss">
.btn-green {
  background: #00c390;
  color: #f1f1f1;
  &:hover {
    color: #fff;
  }
}
.modal-installments {
  .modal-dialog {
    max-width: 950px !important;
  }
}

.modal-search-customer {
  .modal-dialog {
    max-width: 650px !important;
  }
}
</style>
<script>
import {
  BTable,
  BModal,
  BBadge,
  BCard,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { maskCpf } from "@/_helpers/_urlHelper";

import CustomerModalCreate from "@/views/customer/customer/components/Modal/Create";

export default {
  components: {
    BTable,
    BModal,
    BBadge,
    BCard,
    BButton,
    BSpinner,
    CustomerModalCreate,
  },
  data() {
    return {
      classroomUuid: "",
      classroom: "",
      btnSearchCustomer: false,
      searchCustmer: {
        cpf: "",
        email: "",
        cellphone: "",
      },
      customers: null,
      fieldsProducts: [
        { key: "title", label: "Curso", class: "text-left" },
        { key: "installments", label: "Parcelamento", class: "text-center" },
        { key: "redirect", label: "", class: "text-center" },
      ],
      fieldsDatasheet: [
        { key: "situation", label: "Situação", class: "text-left" },
        { key: "states", label: "Abrangência", class: "text-center" },
        { key: "remuneration", label: "Remuneração", class: "text-center" },
        { key: "contract_model", label: "Contratação", class: "text-center" },
        { key: "levels", label: "Nível", class: "text-center" },
      ],
      fieldsCourses: [
        { key: "course_title", label: "Curso", class: "text-left" },
        { key: "classrooms", label: "Calendário", class: "text-center" },
        { key: "disciplines", label: "Disciplinas", class: "text-center" },
        { key: "installments", label: "Parcelamento", class: "text-center" },
      ],
      fieldsClassrooms: [
        {
          key: "unit_name",
          label: "Unidade",
          class: "text-center",
          sortable: true,
        },
        {
          key: "classroom_name",
          label: "Turma",
          class: "text-center",
          sortable: true,
        },
        {
          key: "period",
          label: "Período",
          class: "text-center",
          sortable: true,
        },
        {
          key: "days",
          label: "Dias de Aula",
          class: "text-center",
          sortable: true,
        },
        {
          key: "confirmed",
          label: "Confirmação",
          class: "text-center",
          sortable: true,
        },
        {
          key: "postponement",
          label: "Início",
          class: "text-center",
          sortable: true,
        },
        { key: "actions", label: "", class: "text-center" },
      ],
      fieldsDisciplines: [
        { key: "discipline_title", label: "Disciplina", class: "text-left" },
        { key: "workload", label: "Carga Horária", class: "text-center" },
      ],
      fieldsInstallments: [
        { key: "installment", label: "Parcela", class: "text-center" },
        { key: "value", label: "Valor", class: "text-center" },
        { key: "card_debit", label: "Débito", class: "text-center" },
        { key: "card_credit", label: "Crédito", class: "text-center" },
        { key: "pix", label: "PIX", class: "text-center" },
        { key: "money", label: "Dinheiro", class: "text-center" },
        { key: "billet", label: "Boleto", class: "text-center" },
        { key: "letter", label: "Carta C.", class: "text-center" },
        { key: "check", label: "Cheque", class: "text-center" },
      ],
      course: "",
      courses: [],
      classrooms: [],
      description: "",
      disciplines: [],
      itemsClassrooms: [],
      itemsDisciplines: [],
      itemsInstallments: [],
      payment_forms: [
        {
          code: "dinheiro",
          icon: "bi-cash-stack",
          title: "Dinheiro",
        },
        {
          code: "debito",
          icon: "bi-credit-card-2-front",
          title: "Débito",
        },
        {
          code: "credito",
          icon: "bi-credit-card-2-back",
          title: "Crédito",
        },
        {
          code: "pix",
          icon: "bi-qr-code-scan",
          title: "PIX",
        },
        {
          code: "cheque",
          icon: "bi-card-text",
          title: "Cheque",
        },
      ],
      installments: [],
      totalWorkload: 0,
      products: [],
      product: null,
    };
  },
  async mounted() {
    this.$store.dispatch("Course/fetchSalesPanel").then((data) => {
      this.courses = data;
    });

    this.getProducts();

    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === "modal-create-customer") {
        this.submitSearchCustomer();
      }
    });
  },
  directives: {
    Ripple,
  },
  methods: {
    redirectToPage(customer) {
      const code = this.classroom?.classroom_uuid || this.product?.code;

      this.$router.push({
        name: "customers-enrollment-create",
        params: { uuid: customer.uuid },
        query: {
          code: code,
          mode: this.classroom ? "classroom" : "product",
        },
      });
    },
    async getProducts() {
      this.$store
        .dispatch("Product/searchOfCategoriesByStatus", {
          term: "",
          categories: [7],
          status: 1,
        })
        .then((res) => {
          this.products = res.data;
        });
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    openModalCustomerCreate() {
      this.$bvModal.show("modal-create-customer");
    },
    submitSearchCustomer() {
      if (
        this.searchCustmer.cpf ||
        this.searchCustmer.email ||
        this.searchCustmer.cellphone
      ) {
        this.btnSearchCustomer = true;
        this.$store
          .dispatch("Customer/search", this.searchCustmer)
          .then((data) => {
            this.customers = data;
          })
          .finally(() => {
            this.btnSearchCustomer = false;
          });
      } else {
        this.notifyDefault("error", "Preencha pelo menos 1 campo.");
      }
    },

    openModalSearchCustomer(item, mode) {
      if (mode === "classroom") {
        this.classroom = item;
        this.product = null;
      } else {
        this.classroom = null;
        this.product = item;
      }

      this.$bvModal.show("modal-search-customer");
    },

    openModalCourse(course) {
      this.course = course;
      this.$bvModal.show("modal-course");
    },

    openModalProduct(product) {
      this.product = product;
      this.$bvModal.show("modal-product");
    },

    openModalClassroom(course) {
      this.course = course;
      this.itemsClassrooms = course.classrooms;
      this.$bvModal.show("modal-classrooms");
    },

    openModalDisciplines(course) {
      this.course = course;
      this.itemsDisciplines = course.disciplines;
      this.totalWorkload = 0;

      let duracaoTotal = moment.duration(0);

      for (const discipline of course.disciplines) {
        let duracao = moment.duration(discipline.workload);
        duracaoTotal.add(duracao);
      }

      let horas = Math.floor(duracaoTotal.asHours());
      let minutos = duracaoTotal.minutes();

      this.totalWorkload = `${String(horas).padStart(2, "0")}:${String(
        minutos
      ).padStart(2, "0")}`;

      this.$bvModal.show("modal-disciplines");
    },

    openModalInstallments(course) {
      this.course = course;

      this.itemsInstallments = course.installments;

      this.$bvModal.show("modal-installments");
    },
  },
};
</script>
