var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("h4", { staticClass: "my-2" }, [_vm._v("Painel de vendas")]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              striped: "",
              items: _vm.products,
              fields: _vm.fieldsProducts,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-block btn-dark btn-block text-left",
                        staticStyle: { height: "42px" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalProduct(item)
                          },
                        },
                      },
                      [_c("strong", [_vm._v(" " + _vm._s(item.title) + " ")])]
                    ),
                  ]
                },
              },
              {
                key: "cell(installments)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-dark",
                        staticStyle: { height: "42px" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalInstallments(item)
                          },
                        },
                      },
                      [
                        _vm._v(" Parcelamento "),
                        _c(
                          "b-badge",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              color: "black",
                            },
                            attrs: { variant: "light" },
                          },
                          [_vm._v(" " + _vm._s(item.installments.length) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(redirect)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-sm btn-dark",
                        staticStyle: { height: "42px" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalSearchCustomer(item, "product")
                          },
                        },
                      },
                      [_vm._v(" Matricular ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              striped: "",
              items: _vm.courses,
              fields: _vm.fieldsCourses,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(course_title)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-green text-left",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalCourse(item)
                          },
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v(" " + _vm._s(item.course_title) + " "),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(classrooms)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-green",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalClassroom(item)
                          },
                        },
                      },
                      [
                        _vm._v(" Turmas "),
                        _c(
                          "b-badge",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              color: "black",
                            },
                            attrs: { variant: "light" },
                          },
                          [_vm._v(" " + _vm._s(item.classrooms.length) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(disciplines)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-green",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalDisciplines(item)
                          },
                        },
                      },
                      [
                        _vm._v(" Disciplinas "),
                        _c(
                          "b-badge",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              color: "black",
                            },
                            attrs: { variant: "light" },
                          },
                          [_vm._v(" " + _vm._s(item.disciplines.length) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(installments)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-block btn-green",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalInstallments(item)
                          },
                        },
                      },
                      [
                        _vm._v(" Parcelamento "),
                        _c(
                          "b-badge",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              color: "black",
                            },
                            attrs: { variant: "light" },
                          },
                          [_vm._v(" " + _vm._s(item.installments.length) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            scrollable: "",
            size: "lg",
            title: "Detalhes do produto",
            id: "modal-product",
            "modal-class": "modal-primary",
          },
        },
        [
          _vm.product
            ? _c("h6", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.product.title)),
              ])
            : _vm._e(),
          _vm.product && _vm.product.datasheet
            ? _c("div", {
                staticClass: "alert alert-default p-1",
                staticStyle: {
                  background: "#f5f5f5bf",
                  color: "#000",
                  border: "1px solid #c9c9c9",
                },
                domProps: { innerHTML: _vm._s(_vm.product.datasheet) },
              })
            : _vm._e(),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "hide-footer": "",
            title: "Detalhes do Curso",
            centered: "",
            size: "lg",
            id: "modal-course",
            "modal-class": "modal-primary",
          },
        },
        [
          _c("h6", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.course.course_title)),
          ]),
          _vm.course.datasheet && _vm.course.datasheet.description
            ? _c("div", {
                staticClass: "alert alert-default p-1",
                staticStyle: {
                  background: "#f5f5f5bf",
                  color: "#000",
                  border: "1px solid #c9c9c9",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.course.datasheet.description),
                },
              })
            : _vm._e(),
          _c("b-table", {
            staticClass: "mb-2",
            attrs: {
              responsive: "",
              items: [_vm.course.datasheet],
              striped: "",
              fields: _vm.fieldsDatasheet,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(situation)",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.course.datasheet.situation_name) + " "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(remuneration)",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.course.datasheet.remuneration_prefix) +
                        " " +
                        _vm._s(_vm.course.datasheet.remuneration_value) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(levels)",
                fn: function (ref) {
                  var item = ref.item
                  return _vm._l(item.levels, function (level, index) {
                    return _c("span", { key: index }, [
                      _vm._v(" " + _vm._s(level.name) + " "),
                      index !== item.levels.length - 1
                        ? _c("span", [_vm._v(",")])
                        : _vm._e(),
                    ])
                  })
                },
              },
              {
                key: "cell(contract_model)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.contract_model) + " ")]
                },
              },
              {
                key: "cell(states)",
                fn: function (ref) {
                  var item = ref.item
                  return _vm._l(item.states, function (state, index) {
                    return _c("span", { key: index }, [
                      _vm._v(" " + _vm._s(state.title) + " "),
                      index !== item.states.length - 1
                        ? _c("span", [_vm._v(",")])
                        : _vm._e(),
                    ])
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "hide-footer": "",
            title: "Calendário",
            centered: "",
            size: "xl",
            id: "modal-classrooms",
            "modal-class": "modal-primary",
          },
        },
        [
          _c("h6", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.course.course_title)),
          ]),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              striped: "",
              items: _vm.itemsClassrooms,
              fields: _vm.fieldsClassrooms,
              "primary-key": "classroom_name",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(days)",
                fn: function (ref) {
                  var item = ref.item
                  return _vm._l(item.days, function (day, index) {
                    return _c("span", { key: index }, [
                      _vm._v(
                        " " +
                          _vm._s(day.name) +
                          " " +
                          _vm._s(index !== item.days.length - 1 ? "," : "") +
                          " "
                      ),
                    ])
                  })
                },
              },
              {
                key: "cell(confirmed)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.confirmed
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(unit_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.unit_name || "Live") + " ")]
                },
              },
              {
                key: "cell(classroom_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.classroom_name) + " ")]
                },
              },
              {
                key: "cell(actions)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openModalSearchCustomer(
                              item,
                              "classroom"
                            )
                          },
                        },
                      },
                      [_vm._v(" Matricular ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(period)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.shift_name) +
                        " - " +
                        _vm._s(item.start_time) +
                        " às " +
                        _vm._s(item.end_time) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "hide-footer": "",
            title: "Disciplinas e Carga Horária",
            centered: "",
            size: "md",
            id: "modal-disciplines",
            "modal-class": "modal-primary",
          },
        },
        [
          _c("h6", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.course.course_title)),
          ]),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              striped: "",
              items: _vm.itemsDisciplines,
              fields: _vm.fieldsDisciplines,
            },
          }),
          _c("hr"),
          _c("div", { staticClass: "form-row mt-2" }, [
            _c("div", { staticClass: "col-md-9 text-right text-success" }, [
              _c("strong", [_vm._v("Total")]),
            ]),
            _c("div", { staticClass: "col-md-3 text-center" }, [
              _c("strong", [_vm._v(_vm._s(this.totalWorkload) + " horas")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-body": "",
            "no-close-on-backdrop": "",
            "hide-footer": "",
            title: "Parcelamento",
            centered: "",
            size: "lg",
            id: "modal-installments",
            "modal-class": "modal-primary modal-installments",
          },
        },
        [
          _c("h6", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.course.course_title)),
          ]),
          _c("b-table", {
            staticClass: "mb-0",
            attrs: {
              responsive: "",
              striped: "",
              items: _vm.itemsInstallments,
              fields: _vm.fieldsInstallments,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(value)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toCurrency")(item.value)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(card_debit)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.card_debit
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(card_credit)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.card_credit
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(pix)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.pix
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(money)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.money
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(check)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.check
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(billet)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.billet
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(letter)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.letter
                      ? _c("i", {
                          staticClass: "bi bi-patch-check-fill text-success",
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-body": "",
            "no-close-on-backdrop": "",
            "hide-footer": "",
            title: "Pesquisar Cliente",
            centered: "",
            id: "modal-search-customer",
            "modal-class": "modal-search-customer",
          },
        },
        [
          _vm.classroom
            ? _c("div", { staticClass: "form-row mb-0" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Curso")]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.course.course_title },
                    }),
                  ]),
                ]),
                _vm.classroom
                  ? _c("div", { staticClass: "col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Turma")]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: _vm.classroom.classroom_name },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.classroom
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group mb-md-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Unidade"),
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: _vm.classroom.unit_name },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm.classroom
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c("div", { staticClass: "form-group mb-md-0 mb-0" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Horário"),
                        ]),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: "" },
                          domProps: {
                            value:
                              _vm.classroom.start_time +
                              " às " +
                              _vm.classroom.end_time,
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm.product
            ? _c("h4", [_vm._v(_vm._s(_vm.product.title))])
            : _vm._e(),
          _c("hr"),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("CPF")]),
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["###.###.###-##"],
                    expression: "['###.###.###-##']",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchCustmer.cpf,
                    expression: "searchCustmer.cpf",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "tel" },
                domProps: { value: _vm.searchCustmer.cpf },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.searchCustmer, "cpf", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col-md-5" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("E-mail")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchCustmer.email,
                    expression: "searchCustmer.email",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.searchCustmer.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.searchCustmer, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Celular")]),
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["(##) #####-####", "(##) ####-####"],
                    expression: "['(##) #####-####', '(##) ####-####']",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchCustmer.cellphone,
                    expression: "searchCustmer.cellphone",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "tel" },
                domProps: { value: _vm.searchCustmer.cellphone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.searchCustmer,
                      "cellphone",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning",
                  staticStyle: {
                    "margin-top": "20px",
                    padding: "5px 5px !important",
                    "line-height": "2.5",
                  },
                  attrs: { disabled: _vm.btnSearchCustomer, type: "button" },
                  on: { click: _vm.submitSearchCustomer },
                },
                [
                  !_vm.btnSearchCustomer
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        staticClass: "align-middle",
                        attrs: { small: "" },
                      }),
                ],
                1
              ),
            ]),
          ]),
          _vm.btnSearchCustomer
            ? _c(
                "p",
                {
                  staticClass:
                    "mt-2 d-flex justify-content-left items-center text-left",
                },
                [
                  _c("b-spinner", {
                    staticClass: "align-middle",
                    staticStyle: { "margin-right": "8px" },
                    attrs: { small: "" },
                  }),
                  _vm._v(" buscando cliente "),
                ],
                1
              )
            : _vm._e(),
          _vm.customers && _vm.customers.length
            ? _c("div", { staticClass: "w-full d-block mt-3" }, [
                _c("h5", [
                  _vm._v(" Clientes encontrados "),
                  _c("small", [
                    _vm._v("(" + _vm._s(_vm.customers.length) + ")"),
                  ]),
                ]),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.customers, function (customer, index) {
                    return _c(
                      "li",
                      { key: "c-" + index, staticClass: "list-group-item" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between items-center",
                            staticStyle: { "align-items": "center" },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(customer.full_name) +
                                  " - " +
                                  _vm._s(_vm.toMaskCpf(customer.cpf)) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block btn-link btn-sm",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectToPage(customer)
                                    },
                                  },
                                },
                                [_vm._v(" Matricular ")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : !_vm.btnSearchCustomer && _vm.customers
            ? _c("div", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-between items-center",
                  },
                  [
                    _c("div", [_vm._v("Não encontramos nenhum cliente.")]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.openModalCustomerCreate },
                      },
                      [_vm._v(" Cadastrar Cliente ")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("CustomerModalCreate"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }